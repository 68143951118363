import React, { useEffect, useState } from 'react';

export default function PartyCard({data, state}) {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(state)
    }, [state])

    return (
        <div>
            {data[0].text.length > 0 ? (
                    <div className='flex justify-center items-center flex-col'>
                        <div className='p-4 rounded-xl shadow-2xl bg-gray-700 border border-gray-400'>
                            {loading ? ( // use 'loading' instead of 'isLoading'
                                <div>Lade...</div>
                            ) : (
                                <div>
                                    <div className='flex text-center justify-center items-center'>
                                        <h1 className='text-gray-200 text-lg md:text-xl lg:text-2xl'>Aktuelles zur Party:</h1>
                                    </div>
                                    <div className='text-gray-200 p-4'>
                                        <div dangerouslySetInnerHTML={{ __html: data[0].text }} />
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                ) : (
                    <>
                    </>
                )}
        </div>
    )
}
