import React, {useEffect} from 'react';
import Navbar from "../navbar";
import Footer from "../footer";
import MainAboutus from "../mainComponents/aboutus/mainAboutus";

export default function AboutUs(){

    useEffect(() => {
        document.title = 'Halle Höchst | About Us'
    }, [])


    return (
        <main>
            <Navbar/>
            <MainAboutus/>
            <Footer/>
        </main>
    );
}