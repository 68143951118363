import React from 'react';
import {motion} from 'framer-motion'
import {Link} from 'react-router-dom'

export default function GalleryCard({id, name, frontPic}) {
    return (
        <>
        {frontPic ? (
                <Link to={'/gallery/album/' + id}>
                    <motion.div
                        className='flex flex-col cursor-pointer px-8 shadow-xl justify-center min-h-[500px] max-h-[650px] items-center md:w-[300px] gap-4 rounded bg-gray-700'
                        whileHover={{scale: 1.1}}
                        transition={{duration: 0.4}}
                    >
                        <div className='flex justify-center items-center'>
                            {frontPic ? (
                                <img src={'https://admin.halle-höchst.de/uploads/' + frontPic}
                                     className='md:w-[230px] w-[200px] h-auto rounded-lg object-cover'
                                     alt='erstes Bild des Albums'/>
                            ) : (
                                <div>
                                    <h2 className='text-white'>Bilder sind auf dem Weg!</h2>
                                </div>
                            )}

                        </div>
                        <div className='text-center w-full flex justify-center px-4'>
                            <h2 className='text-gray-200 uppercase md:text-[15px] text-[13px] font-bold overflow-hidden overflow-ellipsis'>{name}</h2>
                        </div>

                    </motion.div>
                </Link>
            ) : (
                <div
                    className='flex flex-col px-8 shadow-xl justify-center h-[400px] items-center md:w-[300px] gap-4 rounded bg-gray-700'
                >
                    <div className='flex justify-center items-center'>
                        {frontPic ? (
                            <img src={'https://admin.halle-höchst.de/uploads/' + frontPic}
                                 className='md:w-[230px] w-[200px] h-auto rounded-lg object-cover'
                                 alt='erstes Bild des Albums'/>
                        ) : (
                            <div>
                                <h2 className='text-white'>Bilder sind auf dem Weg!</h2>
                            </div>
                        )}

                    </div>
                    <div className='text-center w-full flex justify-center px-4'>
                        <h2 className='text-gray-200 uppercase md:text-[15px] text-[13px] font-bold overflow-hidden overflow-ellipsis'>{name}</h2>
                    </div>

                </div>
            )}
        </>

    )
}