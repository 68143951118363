import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import {Captions} from "yet-another-react-lightbox/plugins";



function formatDateTime(isoDateTime) {
    const date = new Date(isoDateTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

const NewsCard = ({ title, timestamp, content, isOpen, toggle, picture }) => {
    const [index, setIndex] = useState(-1);
        return (
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 1.5}}
                className="bg-gray-700 flex flex-col mt-8 mb-8 gap-4 rounded-lg p-4 shadow-md w-full"
            >
                <h2 className="text-xl text-gray-200 font-semibold">{title}</h2>
                <p className="text-gray-200 text-sm mb-2">{formatDateTime(timestamp)}</p>
                <AnimatePresence>
                    {isOpen ? (
                        <motion.div
                            key="content"
                            initial={{height: 0, opacity: 0}}
                            animate={{height: "auto", opacity: 1}}
                            exit={{height: 0, opacity: 0}}
                            transition={{duration: 0.3}}
                            className="text-gray-200 markdown-custom w-full flex flex-col"
                            style={{overflow: "hidden"}}
                        >
                            <motion.div className="flex flex-col gap-4" style={{width: "100%"}}>
                                {picture ? (
                                    <div className='flex flex-col justify-center items-center'>
                                        <img src={'https://admin.halle-hoechst.de/uploads/' + picture} className='w-96 cursor-pointer'
                                             onClick={() => setIndex(0)}
                                             alt='News Bild'/>
                                        <PhotoAlbum
                                            className='!rounded-3xl w-full h-full shadow-2xl'
                                            layout="rows"
                                            disableSwipe={true}
                                            photos={[{src: "https://admin.halle-hoechst.de/uploads/" + picture, width: 1000, height: 800}]}
                                            onClick={({index: current}) => setIndex(current)}
                                        />
                                        <Lightbox
                                            index={index}
                                            slides={[{src: "https://admin.halle-hoechst.de/uploads/" + picture, width: 1000, height: 800}]}
                                            disableSwipe={true}
                                            open={index >= 0}
                                            close={() => setIndex(-1)}
                                        />
                                    </div>


                                ) : (
                                    <>
                                    </>
                                )}
                                <div className={picture ? 'text-center' : ''} dangerouslySetInnerHTML={{__html: content}}/>
                            </motion.div>
                        </motion.div>
                    ) : null}
                </AnimatePresence>
                <button
                    onClick={toggle}
                    className="decoration-0 hover:bg-gray-600 duration-300 cursor-pointer w-[130px] p-1.5 md:pl-6 md:pr-6 pl-6 pr-6 bg-gray-700 rounded-lg border border-gray-400 text-gray-200"
                >
                    {isOpen ? 'Schließen' : 'Öffnen'}
                </button>
            </motion.div>
        )

    }
;

export default function MainNews() {
    const [blogEntries, setBlogEntries] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const [expandedText, setExpandedText] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://admin.halle-hoechst.de/news/all',
        };

        axios.request(config)
            .then((response) => {
                setTimeout(() => {
                    setBlogEntries(response.data);
                    setIsLoading(false);
                }, 1500);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });

    }, []);

    const toggleExpanded = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(-1);
            setExpandedText({});
        } else {
            setExpandedIndex(index);
            setExpandedText({[index]: blogEntries[index].content});
        }
    };

    return (
        <div className="flex w-full justify-center items-center md:px-20 px-8 flex-col">
            <div className="w-full gap-12 pt-16 pb-16 md:pl-32 md:pr-32">

                <div className="w-full">
                    <h1 className="text-center md:text-5xl text-3xl font-bold text-gray-200">News</h1>
                </div>
                {isLoading ? (
                    <div className="text-center">
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 1.5}}
                        >
                            <div
                                className="inline-block h-12 w-12 mt-4 animate-spin rounded-full border-4 border-solid border-gray-300 border-current border-r-transparent align-[-0.125em] text-primary">
                                <span
                                    className="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap text-gray-200 border-0 p-0 clip-[rect(0,0,0,0)]">Lädt, bitte warten.</span>
                            </div>
                        </motion.div>
                    </div>
                ) : (
                    Array.isArray(blogEntries) && blogEntries.length > 0 ? (
                        blogEntries.map((entry, index) => (
                            <NewsCard
                                key={index}
                                title={entry.title}
                                timestamp={entry.timestamp}
                                content={entry.content}
                                picture={entry.picture}
                                isOpen={expandedIndex === index}
                                toggle={() => toggleExpanded(index)}
                            />
                        ))
                    ) : (
                        <div className="text-center h-[300px] pt-8">
                            <p className="text-gray-200 text-2xl">Derzeit keine News verfügbar</p>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}
