import React, {useEffect} from 'react';
import Navbar from "../navbar";
import Footer from "../footer";
import MainNews from "../mainComponents/news/newsMain";

export default function News() {

    useEffect(() => {
        document.title = 'Halle Höchst | News'
    }, [])

    return (
        <main>
            <Navbar/>
            <MainNews/>
            <Footer/>
        </main>
    )
}