import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import "./App.css";
import ErrorPage from "./components/pages/404";
import {Helmet} from "react-helmet";
import Home from "./components/pages/home";
import Gallery from "./components/pages/gallery";
import Album from "./components/pages/album";
import News from "./components/pages/news";
import Eckistipps from "./components/pages/eckistipps";
import Impress from "./components/pages/impress";
import AboutUs from "./components/pages/aboutus";
import Videos from "./components/pages/videos";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/home",
        element: <Home/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/gallery",
        element: <Gallery/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/gallery/album/:id",
        element: <Album/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/news",
        element: <News/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/eckistipps",
        element: <Eckistipps/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/impress",
        element: <Impress/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/aboutus",
        element: <AboutUs/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/videos",
        element: <Videos/>,
        errorElement: <ErrorPage/>
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(

    <React.StrictMode>
        <Helmet>
            <meta name="description"
                  content="Der Freizeitclub 'Die Halle Höchst' ist eine um Silvester 89/90 ins Leben gerufene Gemeinschaft junger Leute, die sich zu der Zeit einmal wöchentlich immer mittwochs im alten Ortskern von Höchst (bei Gelnhausen) in einer alten Werkzeughalle 'bei Rüdis Omma' trafen, um dort bei dem ein oder anderen Getränk (es gab auch Kaffee ;-) Neuigkeiten auszutauschen, Dart zu spielen und vor allem immer gute Rockmusik zu hören."/>
            <meta name="keywords"
                  content="Frankfurt, Hessen, Deutschland, Hanau, musik, party, club, hallehoechst, freizeit"/>
            <meta name="author" content="Steven Dautrich"/>
            <meta name="robots" content="index,follow"/>
            <meta name="theme-color" content="#000000"/>
            <meta property="og:description"
                  content="Der Freizeitclub 'Die Halle Höchst' ist eine um Silvester 89/90 ins Leben gerufene Gemeinschaft junger Leute, die sich zu der Zeit einmal wöchentlich immer mittwochs im alten Ortskern von Höchst (bei Gelnhausen) in einer alten Werkzeughalle 'bei Rüdis Omma' trafen"/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://www.halle-hoechst.de"/>
            <meta property="og:image"
                  content="https://scontent.fham6-1.fna.fbcdn.net/v/t39.30808-6/327279503_711941650543905_6826243591373910541_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=efb6e6&_nc_ohc=PjbyBTJjCP0AX9WedVz&_nc_ht=scontent.fham6-1.fna&oh=00_AfB6c6SNE0bR0ehJvtiF32BfYkJpjT30oU9az_ljGKGMsw&oe=65CAFC3B"/>
            <link rel="apple-touch-icon" href="./assets/424976601_776900361129144_3970094450910505387_n.jpg"/>
            <script async src="https://analytics-customer.cordes-hosting.net/script.js"
                    data-website-id="4c1e0a12-6d89-4988-a9c2-2bdb9f91014d"></script>
        </Helmet>
        <RouterProvider router={router}/>
    </React.StrictMode>
);