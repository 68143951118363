import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper/modules'
import axios from 'axios'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export default function Partner(){

    const [data, setData] = useState();

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://admin.halle-hoechst.de/partner/list',
        };

        axios.request(config)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    function formatUrl(url){
        if(url.startsWith('www.')){
            return 'https://' + url
        }

        return url
    }


    return(
        <div className='flex bg-gray-700 md:!mx-20 mx-0 rounded-t shadow-xl'>
            <Swiper
                loop={true}
                autoplay={true}
                pagination={false}
                speed={2500}
                modules={[Autoplay]}
                spaceBetween={22}
                slidesPerView={1}
                className="mySwiper !flex w-full !flex-col h-full !justify-center !items-center !rounded-2xl"
            >
                {Array.isArray(data) ? (
                    <Swiper
                        pagination={false}
                        loop={true}
                        autoplay={true}
                        speed={2500}
                        modules={[Autoplay]}
                        spaceBetween={22}
                        slidesPerView={1}
                        className="mySwiper !flex w-full !flex-col h-full !justify-center !items-center rounded-2xl"
                    >
                        {data.map((item, index) => (
                            <SwiperSlide key={index} className='!flex !justify-center !items-center w-full h-2/3 bg-gray-700 rounded-2xl !bg-transparent'>
                                <div className='!flex !justify-center !items-center h-full pt-8 flex-col w-full text-gray-200'>
                                    <a href={formatUrl(item.url)}
                                       className='flex items-center gap-2 justify-center h-full flex-col'
                                       target='_blank'
                                       rel="noreferrer"
                                    >
                                        {item.picture ? (
                                            <>
                                                <img src={'https://admin.halle-hoechst.de/uploads/' + item.picture} className='!w-36' alt={'Frontbild von Partner ' + item.name}/>
                                                <h1 className='font-bold mt-4 text-center text-xl'>{item.name}</h1>
                                                <p className='text-center'>{item.subtext}</p>
                                            </>
                                        ) : (
                                            <>
                                                <h1 className='font-bold mt-4 text-center text-xl'>{item.name}</h1>
                                            </>
                                        )}
                                    </a>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <div className="text-center">
                        <h2>Werde Partner!</h2>
                    </div>
                )}
            </Swiper>
        </div>
    )
}