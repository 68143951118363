import React, {useState, useEffect} from 'react';
import axios from 'axios';
import GalleryCard from "./galleryCard";

export default function MainGallery() {
    const [jsonData, setJsonData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://admin.halle-hoechst.de/gallery/category');
                setJsonData(response.data);
            } catch (error) {
                console.error('Fehler beim Laden der Daten:', error);
            }
        };

        fetchData().then(() => {
        })


    }, []);


    return (
        <div className='flex flex-col justify-center items-center gap-20 w-full md:px-0 px-4 pt-20 pb-20'>
            <div className='text-center pt-2'>
                <h1 className='text-gray-200 md:text-[30px] lg:text-[43px] text-[23px]'>Unsere aktuellen Bilder!</h1>
            </div>
            {jsonData.length > 0 ? (
                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12'>
                    {jsonData.map(album => (
                            <GalleryCard
                                key={album.id}
                                id={album.id}
                                name={album.name}
                                frontPic={album.titleImage ? album.titleImage : album.frontPic}
                            />
                    ))}
                </div>
            ) : (
                <div className='h-[400px] flex justify-center items-center'>
                    <h1 className='text-gray-200 md:text-[30px] text-center lg:text-[43px] text-[23px] pb-8'>Hier werden unsere Alben angezeigt!</h1>
                </div>
            )}

        </div>
    );
}
