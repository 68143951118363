import { useCallback, useEffect, useState } from "react";

const Timer3 = ({ endDate, test }) => {
    const [timeEnded, setTimeEnded] = useState(false);
    const [countDownTime, setCountDownTime] = useState({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
    });

    // Diese Funktion berechnet die Zeitdifferenz und aktualisiert die Zustände.
    const calculateTimeDifference = (targetTime) => {
        const currentTime = new Date().getTime();
        let timeDifference = targetTime - currentTime;

        if (timeDifference <= 0) {
            setTimeEnded(true);
            return {
                days: "00",
                hours: "00",
                minutes: "00",
                seconds: "00",
            };
        }

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
        timeDifference %= (1000 * 60 * 60 * 24);
        const hours = Math.floor(timeDifference / (1000 * 60 * 60)).toString().padStart(2, '0');
        timeDifference %= (1000 * 60 * 60);
        const minutes = Math.floor(timeDifference / (1000 * 60)).toString().padStart(2, '0');
        timeDifference %= (1000 * 60);
        const seconds = Math.floor(timeDifference / 1000).toString().padStart(2, '0');

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    };

    const getTimeDifference = useCallback(() => {
        const targetTime = new Date(endDate).getTime();
        const result = calculateTimeDifference(targetTime);
        setCountDownTime(result);
    }, [endDate]);

    useEffect(() => {
        // Initialcheck, ob der Countdown bereits abgelaufen ist.
        const targetTime = new Date(endDate).getTime();
        const initialCountdown = calculateTimeDifference(targetTime);
        setCountDownTime(initialCountdown);

        const interval = setInterval(() => {
            getTimeDifference();
        }, 1000);

        return () => clearInterval(interval); // Cleanup der Interval bei Component Unmount
    }, [getTimeDifference, endDate]);
    return (
        <div className="">
            <div className="flex flex-col items-center justify-center w-full h-full gap-8 sm:gap-16">
                {!timeEnded ? (
                    <h1 className="text-2xl sm:text-3xl text-gray-200 text-center px-2">
                        Zeit bis zur Party!
                    </h1>
                ) : (
                    <>
                    </>
                )}


                {!timeEnded ? (
                    <div className="flex justify-center gap-3 sm:gap-8">
                        <div className="flex flex-col gap-5 relative">
                            <div className="h-12 w-12 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-[#343650] rounded-lg">
                                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[#191A24]"></div>
                                <span className="lg:text-7xl sm:text-6xl text-xl font-semibold text-gray-200">
                {countDownTime?.days}
              </span>
                                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[#191A24]"></div>
                            </div>
                            <span className="text-gray-200 text-xs sm:text-2xl text-center capitalize">
              {countDownTime?.days === 1 ? "Tag" : "Tage"}
            </span>
                        </div>
                        <div className="flex flex-col gap-5 relative">
                            <div className="h-12 w-12 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-[#343650] rounded-lg">
                                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[#191A24]"></div>
                                <span className="lg:text-7xl sm:text-6xl text-xl font-semibold text-gray-200">
                {countDownTime?.hours}
              </span>
                                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[#191A24]"></div>
                            </div>
                            <span className="text-gray-200 text-xs sm:text-2xl text-center font-medium">
              {countDownTime?.hours === 1 ? "Stunde" : "Stunden"}
            </span>
                        </div>
                        <div className="flex flex-col gap-5 relative">
                            <div className="h-12 w-12 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-[#343650] rounded-lg">
                                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[#191A24]"></div>
                                <span className="lg:text-7xl sm:text-6xl text-xl font-semibold text-gray-200">
                {countDownTime?.minutes}
              </span>
                                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[#191A24]"></div>
                            </div>
                            <span className="text-gray-200 text-xs sm:text-2xl text-center capitalize">
              {countDownTime?.minutes === 1 ? "Minute" : "Minuten"}
            </span>
                        </div>
                        <div className="flex flex-col gap-5 relative">
                            <div className="h-12 w-12 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-[#343650] rounded-lg">
                                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[#191A24]"></div>
                                <span className="lg:text-7xl sm:text-6xl text-xl font-semibold text-gray-200">
                {countDownTime?.seconds}
              </span>
                                <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[#191A24]"></div>
                            </div>
                            <span className="text-gray-200 text-xs sm:text-2xl text-center capitalize">
              {countDownTime?.seconds === 1 ? "Sekunde" : "Sekunden"}
            </span>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h1 className='text-gray-200 md:text-[30px] lg:text-[43px] text-[23px] pt-8'>Die Party ist am laufen!!</h1>
                    </div>
                )}
            </div>
        </div>
    );
};
export default Timer3;