import React, {useEffect, useState} from 'react';
import Group from '../../../assets/578666_489608304384572_794188582_n.jpg'
import axios from "axios";
import {motion} from "framer-motion";

export default function MainAboutus() {

    const [blogEntries, setBlogEntries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://admin.halle-hoechst.de/timeline/all',
        };

        axios.request(config)
            .then((response) => {
                setTimeout(() => {
                    setBlogEntries(response.data); // Hier aktualisieren Sie den blogEntries-Status mit den zurückgegebenen Daten
                    setIsLoading(false); // Setzen Sie isLoading auf false, um den Ladezustand zu beenden
                }, 1500);
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error);
            });

    }, []);

    /*
    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Intl.DateTimeFormat('de-DE', options).format(date);
    }
    */


    return (
        <div className='flex flex-col justify-center w-full items-start pt-12 pb-12 md:px-28 px-4'>
            <ol className="flex flex-col w-full border-gray-200 dark:border-gray-700">
                {isLoading ? (
                    <div className="text-center w-full">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1.5 }}
                        >
                            <div className="inline-block h-12 w-12 mt-4 animate-spin rounded-full border-4 border-solid border-gray-300 border-current border-r-transparent align-[-0.125em] text-primary">
                                <span className="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap text-gray-200 border-0 p-0 clip-[rect(0,0,0,0)]">Lädt, bitte warten.</span>
                            </div>
                        </motion.div>
                    </div>
                ) : (
                    Array.isArray(blogEntries) && blogEntries.length > 0 ? (
                        blogEntries.map((entry, index) => (
                            <li className="mb-10 ms-4">
                                <time className="mb-1 text-sm font-normal leading-none text-gray-400">
                                    {entry.timestamp}
                                </time>
                                <h3 className="text-lg font-semibold text-gray-300">
                                    {entry.title}
                                </h3>
                                <p className="mb-4 text-base font-normal text-gray-200">
                                    <div dangerouslySetInnerHTML={{ __html: entry.content }} />
                                </p>
                            </li>
                        ))
                    ) : (
                        <div className="text-center h-[300px] pt-8">
                            <p className="text-gray-200 text-2xl">Derzeit keine News verfügbar</p>
                        </div>
                    )
                )}
            </ol>
            <div className='w-full flex items-center justify-center px-4 pt-8'>
                <img src={Group} className='md:w-[1100px] md:h-full w-[600px] h-auto rounded shadow-2xl' alt='Gruppenfoto der Halle-Höchst'/>
            </div>
        </div>
    )
}