import React, {useEffect} from 'react';
import Navbar from "../navbar";
import Footer from "../footer";
import MainAlbum from "../mainComponents/album/mainAlbum";

export default function Album(){

    useEffect(() => {
        document.title = 'Halle Höchst | Alben'
    }, [])

    return(
        <main>
            <Navbar/>
            <MainAlbum/>
            <Footer/>
        </main>
    )
}